#login {}

body.login {
	background-color: #000;
	
	h1 a {
		background: url('../img/Logo.png') no-repeat top center;
		width: 350px;
		height: 116px;
		text-indent: -9999px;
		overflow: hidden;
		padding: 5px;
		display: block;
		margin: 0;
	}
}

#nav {
	a {
		color: #FFF;
	}
}

form {
	margin-left: 8px;
	padding: 26px 24px 46px;
	font-weight: normal;
	background: white;
	border: 1px solid #e5e5e5;
	border-radius: 3px;
	box-shadow: none;

	.input {
		font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", sans-serif;
		font-weight: 200;
		font-size: 24px;
		width: 97%;
		padding: 3px;
		margin-top: 2px;
		margin-right: 6px;
		margin-bottom: 16px;
		border: 1px solid #e5e5e5;
		background: #fbfbfb;
		outline: none;
		box-shadow: inset 1px 1px 2px rgba(200, 200, 200, 0.2);
	}
}

body #login {
	width: 350px;
}